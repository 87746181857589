.decoration2 {
    --color1: rgb(122, 202, 236);
    --color2: #2c3e50;
    --speed: 4s;
    --rect: 8px;

    display: block;
}

.decoration2 .row {
    display: flex;
    margin-bottom: 4px;
}

.decoration2 .square-animation0 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
}
.decoration2 .square-animation1 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe .6s ease infinite;
}
.decoration2 .square-animation2 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe .8s ease infinite;
}
.decoration2 .square-animation3 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 1s ease infinite;
}
.decoration2 .square-animation4 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 1.2s ease infinite;
}
.decoration2 .square-animation5 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 1.4s ease infinite;
}
.decoration2 .square-animation6 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 1.6s ease infinite;
}
.decoration2 .square-animation7 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 1.8s ease infinite;
}
.decoration2 .square-animation8 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 2s ease infinite;
}
.decoration2 .square-animation9 {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
    animation: deco-breathe 2.2s ease infinite;
}

.decoration2 .square {
    height: var(--rect);
    width: var(--rect);
    margin-right: 4px;
    
    background-color: var(--color1);
}

@keyframes deco-breathe {
	0% {
        opacity: 0;
        background-color:rgb(255,255,255);
    }
    100% {
        opacity: 1;
        background-color:var(--color1);
    }
}