.ho-setting-server-card{
    /* background: #e8e8e8; */
}

.ho-setting-server {
    width: 100%;
    padding-bottom: 3rem;
}

.ho-setting-server .tabs .ant-tabs-bar {
    margin: 0;
    border: 0;
}

.ho-setting-server .tabs .ant-tabs-content {
    border: 1px solid #e8e8e8;
    padding: 2rem 1rem;
}

.ho-setting-server .servers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ho-setting-server .servers ul {
    list-style:none;
    margin:0px;
} 

.ho-setting-server .servers ul li .server,
.ho-setting-server .servers ul li .add-server {
    width: 400px;
    border-radius: 4px;
    margin: 0 1rem 1rem 0;
    float:left;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1)!important;
} 

.ho-setting-server .servers ul li .server:hover {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3)!important;
    /* transform: translateY(-8px)!important; */
}

.ho-setting-server .servers ul li .add-server {

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;

    border-radius: 4px;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
}

.ho-setting-server .servers ul li .signal-server {
    min-height: 346px;
}

.ho-setting-server .servers ul li .media-server {
    min-height: 422px;
}

.ho-setting-server .servers ul li .add-server:hover {
    border-color: #1890ff;
    cursor: pointer;
}

.ho-setting-server .servers ul li .add-server .operation {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 4rem;
    user-select: none;
}

.ho-setting-server .servers ul li .add-server .operation .icon {
    font-size: 22px;
}

.ho-setting-server .servers ul li .add-server .ant-card-body {
    display: none;
}

.server .ant-card-body {
    background-color: #fafafa7f;
}

.server .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px dashed #d9d9d9;
}

.server .header .title {
    width: 70%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.server .header .status {
    width: 30%;
    text-align: end;
}

.server .ant-descriptions-item-content .status{
    margin-left: 1rem;
    display: inline-flex;
}

.server .ant-descriptions-item-content .status .loading-description{
    margin-left: 1rem;
    color: #8c8c8c;
}

.server .header .license-code {
    margin-right: 1rem;
}

.license-code-toolip .ant-descriptions-item-label,
.license-code-toolip .ant-descriptions-item-content{
    color: #fff;
}

.warn,
.disable {
    color: #f5222d;
}

.enable {
    color: #1890ff;
}

.server-version-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.server-version {
    padding: 12px 16px;
    border: #bfbfbf 1px dashed;
    border-radius: 4px;
    margin: 16px 0;
}

.server-version .ant-form-item {
    margin-bottom: 0;
}

.server-version .ant-form-item-label {
    text-align: start;
}

.latest {
    margin-left: 0.5rem;
}

.upgarade-collapse {
    border-radius: 4px;
    border: 0;
    overflow: 'hidden';
}

.upgarade-collapse .ant-collapse-item {
    border: 0;
}