
.detail .base .header {
    margin-bottom: 1rem;
}

.detail .base .header .loading {
    margin-right: 1rem;
}

.detail-tabs {
    margin: 12px 36px 0px 24px!important;
    /* background: #fff; */
}

.detail-tabs .ant-tabs-content {
    margin-top: -16px;
    /* padding: 0 24px 24px 24px; */
}
  
.detail-tabs .ant-tabs-content .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}
  
.detail-tabs .ant-tabs-bar {
    border-color: #fff;
}
  
.detail-tabs .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent!important;
    background: transparent!important;
}
  
.detail-tabs .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff!important;
    background: #fff!important;
}

.detail-tabs .tabpane .ant-collapse {
    /* border: none; */
    /* background-color: #fff; */
}

.detail-tabs .tabpane .content .ant-collapse-item > .ant-collapse-header .header {
    user-select: none;
}

.detail-tabs .tabpane .content .ant-collapse-item > .ant-collapse-header .header .title{
    display: inline-block;
    width: 8rem;
    padding-right: 1rem;
}

.detail-tabs .tabpane .content .ant-collapse-item > .ant-collapse-header .header .btn{
    float: right;
}

.detail-tabs .tabpane .summary {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.timeline .row {
    cursor: default;
    user-select: none;
}

.timeline .description {
    display: initial;
    vertical-align: initial;
    margin-left: 1rem;
    color: #1890ff;
}

.timeline .reason {
    margin-left: 1rem;
    color: #f5222d;
}

.apis .ant-collapse-content-box {
    display: flex;
}

.apis .api .req-body,
.apis .api .rsp-body {
    font-family: inherit;
    margin-bottom: 0;
    flex: 1 1;
    padding: 1rem;
    border-radius: 4px;
    margin: 0 4px;
}

.apis .api .req-body {
    background-color:#ffd8bf;
}

.apis .api .rsp-body {
    background-color: #d9f7be;
}