.form-item-addr {
    margin-bottom: 0;
}

.form-item-addr-ip {
    display: inline-block;
    width: 70%;
}

.form-item-addr-ip .ant-form-item-children > input {
    border-radius: 2px 0 0 2px;
    border-right: 0;
}

.form-item-addr-port {
    display: inline-block;
    width: 30%;
}

.form-item-addr-port .ant-form-item-children > input {
    border-radius: 0 2px 2px 0;
}