.ho-blacklist-header {
    margin-bottom: 1rem;
}

.ho-blacklist-server-type-select {
    margin-right: 1rem;
    min-width: 240px;
}

.ho-blacklist-server-select {
    min-width: 240px;
}