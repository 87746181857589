
.horn{
    position: relative;
    width: 100%;
    height: 100%;
    border:1px solid #02a6b5af;
    /* background: rgba(0,0,0,.2); */
    background-image: radial-gradient(circle, rgba(0,0,0,0.1), rgba(0,0,0,0.2));
    /* background-image: url('../../resource/images/bEarthpng.png'); */
}

.horn .lt,
.horn .rt,
.horn .rb,
.horn .lb {
    width: 10px;
    height: 10px;
    position:absolute;
}
.horn .lt{
    border-top: 2px solid #02a6b5;
    border-left: 2px solid #02a6b5;
    left: 0px;
    top: 0px;
}
.horn .rt{
    border-top: 2px solid #02a6b5;
    border-right: 2px solid #02a6b5;
    right: 0px;
    top: 0px;
}
.horn .rb{
    border-bottom:2px solid #02a6b5;
    border-right: 2px solid #02a6b5;
    right: 0px;
    bottom: 0px;
}
.horn .lb{
    border-bottom:2px solid #02a6b5;
    border-left: 2px solid #02a6b5;
    left: 0px;
    bottom: 0px;
}

.antd-pro-trend-trendItem .antd-pro-trend-down {
    color: #22ed7caf;
}

.antd-pro-trend-trendItem .antd-pro-trend-up {
    color: #f5222daf;
}

.monitor {
    position: relative;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    /* background: radial-gradient(ellipse at bottom, #1890ff 0%, #f0f5ff 100%); */
    /* background-image: url('https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598954143731&di=26d55acb03efbc219424575ecc1f25a6&imgtype=0&src=http%3A%2F%2Fpic.vjshi.com%2F2017-04-24%2F4c3a96ed1d606df8c33d8bb3ee62b3ed%2F00002.jpg%3Fx-oss-process%3Dstyle%2Fwatermark'); */
    /* background-image: url('https://timgsa.baidu.com/timg?image&quality=100&size=b9999_10000&sec=1587532739031&di=266cf6a96921eee8fef34de041b3ceaa&imgtype=0&src=http%3A%2F%2Fimage.imufu.cn%2Fforum%2F201308%2F04%2F171607makuzprysuuytafk.jpg'); */
    background-image: radial-gradient(circle, #120338, #030852);
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-attachment: fixed;


    overflow: hidden;
}

.monitor .monitor-component {
    background: -webkit-linear-gradient(top, transparent 40px, #bfbfbf08 41px),-webkit-linear-gradient(left, transparent 40px, #bfbfbf08 41px);
    background-size: 41px 41px;
}

.monitor .monitor-map {
    position: absolute;
}

.monitor-link-title {
    cursor: pointer;
    font-size: 14px;
}

.monitor-bandwidth-down {
    color: #f5222d;
    margin-left: 1rem;
    overflow: hidden;
    display: inline-flex;
}

.monitor-bandwidth-up {
    color: #22ed7c;
    margin-left: 1rem;
    overflow: hidden;
    display: inline-flex;
}

.monitor-bandwidth-down .icon {
    margin-left: 0.25rem;
    animation: MoveUpDown 2s linear 0s infinite;
}

.monitor-bandwidth-up .icon {
    margin-left: 0.25rem;
    animation: MoveDownUp 2s linear 1s infinite;
}

@keyframes MoveUpDown {
    0% {
        transform: translate(0px, -30px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0px, 30px);
        opacity: 0;
    }
}

@keyframes MoveDownUp {
    0% {
        transform: translate(0px, 30px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(0px, -30px);
        opacity: 0;
    }
}

.monitor-field {
    color: #fff;
}

.monitor-field .label {
    margin-right: 0.5rem;
}

.monitor-title {
    font-size: 14px!important;
    /* text-shadow: 0 0 5px #0780cf; */
    /* text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de; */
}

.monitor-progress .ant-progress-text{
    display: none;
}

.monitor-progress .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
}

.monitor-progress .ant-progress-outer .ant-progress-inner {
    border-radius: 0;
    vertical-align: -0.075rem;
}

.monitor-link-title:hover { 
    color: -webkit-link;
    text-decoration: underline;
}

.monitor-decoration-title .decoration {
    width: 250px;
    height: 30px;
}

.monitor-component {
    padding: 1rem;
}

.monitor-component .ant-card {
    /* background: rgba(255, 255, 255); */
}

.monitor-component .antd-pro-charts-pie-pie {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-pie-pie .antd-pro-charts-pie-total > h4 {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-pie-pie .antd-pro-charts-pie-legendTitle {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-pie-pie .antd-pro-charts-pie-percent {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-meta {
    color: rgba(255, 255, 255);
}

.monitor-component .ant-card-head {
    border-bottom: none;
}

.monitor-component .ant-table-small {
    border: none;
}

.monitor-component .ant-table-small > .ant-table-content > .ant-table-body > table {
    table-layout: fixed;
}

.monitor-component .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
    border: none;
}
.monitor-component .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    color: rgba(255, 255, 255);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
}

.monitor-table-row-1 {
    width: 100%;
    background: radial-gradient(circle, rgba(0, 130, 252, 0.15), rgba(0,0,0,0));
    cursor: pointer;
}

.monitor-table-row-2 {
    cursor: pointer;
}


.monitor-component .ant-table-column-title {
    color: rgba(255, 255, 255);
}

.monitor-component .ant-card-head .ant-card-head-title {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-field-field .antd-pro-charts-field-label {
    color: rgba(255, 255, 255);
}

.monitor-component .antd-pro-charts-field-field .antd-pro-charts-field-number {
    color: rgba(255, 255, 255);
}

.monitor-component .ant-table-placeholder {
    background: transparent;
    border-top: 0;
}

.monitor-component .ant-table-body table {
    border-color: transparent;
}

.monitor-component .ant-empty-description {
    color: rgba(255, 255, 255);
}

.monitor-component .ant-table .row {
    background-color: transparent;
}

.monitor-tooltip .ant-tooltip-inner {
    color: rgba(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.8);
}

.monitor-tooltip .ant-tooltip-placement-top .ant-tooltip-arrow::before {
    box-shadow: 3px 3px 7px rgba(255, 255, 255, 0.8);
}

.monitor-tooltip .ant-tooltip-arrow::before {
    background-color: rgba(255, 255, 255, 0.8);
}

.monitor-header {
    /* margin-bottom: 1rem; */
}

.monitor-header .title {
    top: 50%;
    color: #FFF;
    text-align: center;
    /* font-family: "lato", sans-serif; */
    font-weight: 300;
    font-size: 36px;
    letter-spacing: 6px;
    margin: 0 0 0.5rem 0;
}
.monitor-header .title > span {
    background: -webkit-linear-gradient(#ffffff, #64647c);
    font-weight: 500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.monitor-header .monitor-clock {
    color: #fff;
}

.monitor-body {
    margin: 0.5rem 0;
}

.monitor-main {
    display: contents;
    text-align: center;
    min-height: 256px;
}

.monitor-main .body {
    text-align: center;
    top: 2rem;
}

.monitor-main .header .left {
    top: 25px;
    left: 25%;
    position: absolute;
}

.monitor-main .header .right {
    top: 25px;
    left: 25%;
    position: absolute;
}

.monitor-main .antd-pro-number-info-numberInfo .antd-pro-number-info-numberInfoValue > span {
    margin-right: 0;
}

.monitor-main .ant-card-body {
    padding: 0!important;
    margin: 0 0.5rem;
}

.monitor-main-number {
    display: inline-flex;
    background: transparent;
    box-shadow: none;
    font-family: '黑体';
    font-size: 36px;
    color: #fdd845;
    font-weight: bold;
    line-height: 44px;
    height: 44px;
}

.monitor-main-number .number:hover { 
    transition: none;
    color: -webkit-link;
    text-decoration: underline;
    cursor: pointer;
}

.monitor-main-number .unit {
    font-size: 28px;
}

.monitor-table .thead,
.monitor-table .tbody {
    width: 100%;
}

.monitor-table .trow {
    list-style: none;
}

.monitor-table .trow .titem1,
.monitor-table .trow .titem2,
.monitor-table .trow .titem3,
.monitor-table .trow .titem4{ 
    display:block; 
    width: 25%;
    float:left;
}

.monitor-table .thead .trow {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 48px;
}

.monitor-table .tbody .trow {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 36px;
}

.monitor-current-server {
    display: contents;
    min-height: 256px;
}

.monitor-current-server .antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-contentFixed {
    position: block!important;
    bottom: none;
}
.monitor-current-server .container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.monitor-current-server .container .item {
    flex: 1;
}

.monitor-current-conference {
    display: contents;
    min-height: 256px;
}
/* 
.monitor-current-conference .antd-pro-charts-miniChart .antd-pro-charts-chartContent {
    top: 0;
} */

.monitor-current-call {
    display: contents;
    min-height: 256px;
}

.monitor-current-device {
    min-height: 256px;
    display: contents;
    min-height: 256px;
    width: 100%!important;
}

.monitor-current-warn {
    position: relative;
    display: contents;
    min-height: 256px;
    color: #fff;

    --green: #05f8d6AF;
    --size: 200px;
}

.monitor-current-warn .ant-card-head-title {
    padding: 16px 0 0 0;
}

.monitor-current-warn .ant-card-body {
    padding: 0px 12px!important;
}

.monitor-current-warn .ant-card-body .container {
    display: grid;
}

.monitor-current-warn .trow .titem1 {
    width: 15%;
}
.monitor-current-warn .trow .titem2 {
    width: 20%;
}
.monitor-current-warn .trow .titem3 {
    width: 40%;
}
.monitor-current-warn .trow .titem4 {
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;    
    white-space: nowrap;
}

.monitor-current-warn .thead .trow .titem1,
.monitor-current-warn .thead .trow .titem2,
.monitor-current-warn .thead .trow .titem3,
.monitor-current-warn .thead .trow .titem4 {
    padding: 4px 0;
    border-bottom: solid 1px #fff;
}

.monitor-current-warn .warn-container {
    position: absolute;
    /* height: 20px; */
    top: -12px;
    left: 4px;
}

.monitor-current-warn .title {
    position: absolute;
    z-index: 200;
}

.monitor-current-abnormal-call {
    display: contents;
    min-height: 256px;
}

.monitor-current-cdr {
    display: contents;
    min-height: 256px;
    color: #fff;
}

.monitor-current-cdr .trow .titem1 {
    width: 35%;
}
.monitor-current-cdr .trow .titem2 {
    width: 35%;
}
.monitor-current-cdr .trow .titem3 {
    width: 30%;
}

.monitor-current-cdr .thead .trow .titem1,
.monitor-current-cdr .thead .trow .titem2,
.monitor-current-cdr .thead .trow .titem3 {
    padding: 4px 0;
    border-bottom: solid 1px #fff;
}

.monitor-current-cdr .ant-card-head-title {
    padding: 16px 0 0 0;
}

.monitor-current-cdr .ant-card-body {
    padding: 0px 12px!important;
}

.radar-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.radar-wrapper .legend,
.radar-wrapper .radar {
    padding: 0 12px;
    flex: 1;
}

.radar-wrapper .description {
    padding: 0 12px;
    flex-basis: 100%;
}

.radar-wrapper .legend {
    
}

.radar-wrapper .legend > ul {
    list-style: none;
}

.radar-wrapper .legend > ul > li {
    padding: 4px 0;
}

.radar-wrapper .legend .row-score,
.radar-wrapper .legend .row {
    display: flex;
    justify-content: space-between;
}

.radar-wrapper .legend .row-score {
    justify-content: center;
    /* border: solid 1px; */
    
    margin-bottom: 1rem;
}

.radar-wrapper .legend .row .title {
    position: relative;
}

.radar-wrapper .legend .row .tag {
    margin: 0;
}

.radar-wrapper .legend .row-score .tag {
    display: block;
    --main-color: #fdd845AF;
    color: var(--main-color);
    font-size: 48px;
    line-height: 56px;
    height: 56px;
    padding: 0 8px;
    width: 100%;

    user-select: none;
    text-align: center;
    border-radius: 4px;
    animation: bglow 1s infinite ease-in-out alternate;
    background: radial-gradient(ellipse,rgba(0,130,252,0.6875), rgba(0,130,252,.05));
}

.radar-wrapper .legend .row-score .img {
    position: absolute;
    height: 64px;
}

.radar-wrapper .radar .scanner {
    margin: 0 auto;
    background: 
    -webkit-radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), 
    -webkit-repeating-radial-gradient(rgba(32, 255, 77, 0) 2%, rgba(32, 255, 77, 0) 18%, var(--green) 19%, rgba(32, 255, 77, 0) 20%), 
    -webkit-linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, var(--green) 50%, var(--green) 50%, rgba(32, 255, 77, 0) 50.2%), 
    -webkit-linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, var(--green) 50%, var(--green) 50%, rgba(32, 255, 77, 0) 50.2%);
    width: var(--size);
    height: var(--size);
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--green);
    overflow: hidden;
}

.radar .scanner:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: blips 5s infinite;
    animation-timing-function: linear;
    animation-delay: 1.4s;
}

.radar .scanner:after {
    content: ' ';
    display: block;
    background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #05f8d6 100%);
    width: 50%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: radar-beam 5s infinite;
    animation-timing-function: linear;
    transform-origin: bottom right;
    border-radius: 100% 0 0 0;
}


@keyframes radar-beam {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes blips {
    14% {
        background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%);
    }
    14.0002% {
        background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%);
    }
    25% {
        background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%);
    }
    26% {
        background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%);
        opacity: 1;
    }
    100% {
        background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, var(--green) 30%, rgba(255, 255, 255, 0) 100%);
        opacity: 0;
    }
}
  

@keyframes bglow {
    from{
        text-shadow: 0 0 15px var(--main-color) ,
        0 0 15px var(--main-color),
        0 0 7px var(--main-color);
    }
    to{
        text-shadow: 0 0 15px var(--main-color) ,
        0 0 6px var(--main-color),
        0 0 8px var(--main-color);
    }
}