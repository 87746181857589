.loading1{
    display: inline-block;
    width: 19px;
    height: 19px;
    margin-right: 4px;
}
.loading1 span{

    --primary-color: #05f8d6af;
    --secondary-color: #0082fcaf;

    display: inline-block;
    width: 0.3rem;
    height: 10px;
    border-radius: 0px;
    margin-right: 1px;
    background: var(--primary-color);
    animation: load1 1s ease infinite;
}
@keyframes load1{
    0%,100%{
        height: 10px;
        background: var(--primary-color);
    }
    50%{
        height: 1px;
        background: var(--secondary-color);
    }
}
.loading1 span:nth-child(2){
    animation-delay: 0.3s;
}
.loading1 span:nth-child(3){
    animation-delay: 0.6s;
}