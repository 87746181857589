



.btn-dir:hover > span,
.btn-file:hover > span,
.btn-cur-dir:hover > span {
    text-decoration: underline;
}

.btn-dir, 
.btn-file,
.btn-cur-dir {
    padding: 0 0.5rem;
}

.cur-dir:last-child .btn-cur-dir {
    color: #1f1f1f;
    cursor: default;
}

.cur-dir:last-child .btn-cur-dir:hover > span {
    text-decoration: none;
}
