


.ho-room {
    width: 100%;
    min-height: 798px;
    padding-bottom: 3rem;
}

.ho-room .rooms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ho-room .rooms ul {
    list-style:none;
    margin:0px;
} 

.ho-room .rooms ul li .room {
    width: 256px;
    border-radius: 4px;
    float:left;
    transition: all 0.3s cubic-bezier(.7,.3,.1,1)!important;
} 

.ho-room .rooms ul li .room .ant-card-body {
    border-top: dashed 1px #cccccc;
    padding: 24px;
}

.ho-room .rooms ul li .room .ant-card-cover > img {
    user-select: none;
}

.ho-room .rooms ul li .room:hover {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.3)!important;
}

.ho-room .rooms ul li .room .ant-card-meta-description > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
}

.ho-room .pagination {
    position: absolute;
    display: inline;
    right: 0;
    bottom: 0;
    margin: 1rem 1.5rem 1rem 0;
}
