.decoration1 {
    --colour1: #f1c40f;
    --colour2: #2c3e50;
    --speed: 4s;
    --width: 113px;
    --height: 80px;

    height: 68px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.decoration1 .bar {
    min-height: 100px;
    border-radius: 10px;
    width: calc(var(--width) * 4);
    box-shadow: 0px 10px 13px -6px #2c3e50;
    background-color: var(--colour2);
    background-image: repeating-linear-gradient(45deg, transparent, transparent 40px, var(--colour1) 40px, var(--colour1) 80px);
    animation: slide var(--speed) linear infinite;
    will-change: background-position;
}

@keyframes slide{
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: var(--width);
    }
}